










































































import { DietaDto } from "@/shared/dtos/dieta-dto";
import { Component, Prop, Vue } from "vue-property-decorator";
import VueApexCharts from "vue-apexcharts";
import { RouterNames } from "@/router/routernames";
import pacienteModule from '@/store/modules/paciente-module';
@Component({
  components: { VueApexCharts }
})
export default class CardDieta extends Vue {
  @Prop() dieta!: DietaDto;

  created() {}
  public get chartOptions() {
    return {
      chart: {
        type: "radialBar"
      },
      plotOptions: {
        radialBar: {
          startAngle: -135,
          endAngle: 225,
          hollow: {
            margin: 0,
            size: "70%",
            background: "#fff",
            image: undefined,
            imageOffsetX: 0,
            imageOffsetY: 0,
            position: "front",
            dropShadow: {
              enabled: true,
              top: 3,
              left: 0,
              blur: 4,
              opacity: 0.24
            }
          },
          track: {
            background: "#fff",
            strokeWidth: "67%",
            margin: 0, // margin is in pixels
            dropShadow: {
              enabled: true,
              top: -3,
              left: 0,
              blur: 4,
              opacity: 0.35
            }
          },
          dataLabels: {
            show: true,
            name: {
              offsetY: -1,
              show: true,
              color: "#888",
              fontSize: "17px"
            },
            value: {
              offsetY: -10,
              formatter: () => Math.ceil(this.dieta.energia) + " Kcal",
              color: "#888",
              fontSize: "18px",
              show: true
            }
          }
        }
      },
      colors: ["#ff9800"],
      fill: {
        type: "gradient",
        gradient: {
          shade: "dark",
          type: "vertical",
          shadeIntensity: 0.5,
          gradientToColors: ["#EECB7E"],
          inverseColors: true,
          opacityFrom: 1,
          opacityTo: 1,
          stops: [0, 100]
        }
      },
      stroke: {
        lineCap: "round"
      },
      labels: [""]
    };
  }

  public goDieta() {
    this.$router.push({
      name: RouterNames.dieta_show,
      query: { id: this.dieta.id_dieta }
    });
  }
  public get stilo() {
    let h = this.$vuetify.breakpoint.height - 150;
    return "height:" + h + "px;overflow: auto;";
  }
  public get cantidades(){
    return !(pacienteModule.paciente.ocultar_cantidades);
  }
}
