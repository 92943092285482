



















import dieta_visualizacionModule from "@/store/modules/dieta_visualizacion-module";
import { Component, Vue } from "vue-property-decorator";
import CardDieta from "./CardDieta.vue";
@Component({
  components: { CardDieta },
})
export default class DietasMenu extends Vue {
  public created() {
    dieta_visualizacionModule.getDietas();
  }
  public get items() {
    return dieta_visualizacionModule.dietas;
  }
}
